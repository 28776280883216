export const routes = {
  homepage: '/admin',
  login: '/login',
  profile: '/profile',
  registrationConfirmation: '/registration-confirmation/:code',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  changePassword: '/profile/change-password',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  admins: {
    list: '/admin/admins',
    create: '/admin/admins/new',
    edit: '/admin/admins/:id',
  },
  companyUsers: {
    list: '/admin/company-users',
  },
  drivers: {
    list: '/admin/drivers',
    edit: '/admin/drivers/:id',
  },
  vehicles: {
    list: '/admin/vehicles',
    edit: '/admin/vehicles/:id',
  },
  elds: {
    list: '/admin/elds',
  },
  companies: {
    list: '/admin/companies',
    edit: '/admin/companies/:id',
    ownerEdit: '/admin/owner/company/:id',
  },
  translations: '/admin/translations',
  logs: {
    list: '/admin/logs',
    details: '/admin/logs/:driverId/:date',
    edit: '/admin/logs/:driverId/:date/edit/:type',
    unidentifiedDriving: '/admin/unidentified-driving',
  },
  reports: {
    list: '/admin/reports',
  },
  fmcsaReports: {
    list: '/admin/fmcsa-list',
  },
};
