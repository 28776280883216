export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const DEFAULT_ADMIN_LOCALE = 'en';
export const NO_INFO = '-';

export const DEFAULT_SLEEP_TIME = 28800; // seconds
export const MIN_SLEEP_TIME_RESET = 1800; // seconds
export const DEFAULT_DRIVE_TIME = 39600; // seconds
export const ADVERSE_DRIVE_TIME = 46800; // seconds
export const ADVERSE_DRIVE_INCREASE = 7200; // seconds
export const MIN_DRIVE_TIME_RESET = 36000; // seconds
export const DEFAULT_SHIFT_TIME = 50400; // seconds
export const ADVERSE_SHIFT_TIME = 57600; // seconds
export const MIN_SHIFT_TIME_RESET = 36000; // seconds
export const DEFAULT_CYCLE_TIME = 252000; // seconds
export const MIN_CYCLE_TIME_RESET = 122400; // seconds
export const CYCLE_TIME_MIN_THRESHOLD = 68400; // seconds
export const CYCLE_TIME_MAX_THRESHOLD = 3600; // seconds
export const SSB_8_HOURS = 28800; // seconds
export const SSB_7_HOURS = 25200; // seconds
export const SSB_2_HOURS = 7200; // seconds
export const SSB_3_HOURS = 10800; // seconds
export const DEFAULT_WARNING_VIOLATION = 60; // minutes
export const RECAP_MAX = 86400;
