export enum Roles {
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  USER = 'USER',
  // eslint-disable-next-line no-unused-vars
  OWNER = 'OWNER',
  // eslint-disable-next-line no-unused-vars
  DRIVER = 'DRIVER',
}
